import {useEffect, useState} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import navigationData from "../data/homepage-navigation.json";
import GET_AGGREGATIONS_AND_USER from '../graphql/queries/aggregations_and_user';
import {useLazyQuery} from "@apollo/client";
import {getDataFromTree} from "@apollo/react-ssr";
import DesktopCottageSearch from "./HomePageSearch/CottageSearch/DesktopCottageSearch/Index";
import Link from "next/link";
import withApollo from "../lib/withApollo";
import MobileCottageSearch from "./HomePageSearch/CottageSearch/MobileCottageSearch/Index";
import {getSessionId} from "../services/session";
import {useMutation} from "@apollo/react-hooks";
import SAVE_LAST_SELECTED_SEARCH_FILTER from "../graphql/mutations/saveLastSelectedSearchFilter";
import {useRouter} from "next/router";
import {FilterSelection} from "../types/filterSelection";
import {SearchInformation} from "../types/graphql/searchInformation";
import {Aggregations} from "../types/graphql/aggregations";
import SmallScreenComponent from "./SmallScreenComponent";
import LargeScreenComponent from "./LargeScreenComponent";
import dynamic from "next/dynamic";

let MobileCamperSearch = dynamic(
    () => import("./Camper/Search/Mobile/CamperSearch"),
    {ssr: false}
);

let DesktopCamperSearch = dynamic(
    () => import("./Camper/Search/Destktop/CamperSearch"),
    {ssr: false}
);

export interface FormattedAggregation {
    value: string
    label: string
    name: string
    count: number
    type: string
    metadata: any
}

const getAggregation = (name, data): FormattedAggregation[] => {
    if (data && data.filter(aggregation => aggregation.type === name)[0] !== undefined) {
        let result = data.filter(aggregation => aggregation.type === name)[0].buckets.map(item => ({
            value: item.identifier,
            label: `${item.label} (${item.count})`,
            name: item.label,
            count: item.count,
            type: item.type,
            metadata: JSON.parse(item.metadata)
        }));
        return result;
    }

    return [];
}

const HomePageSearchBar = ({ apollo }) => {
    const router = useRouter();
    const [sessionId, setSessionId] = useState(null);
    const [searchInformation, setSearchInformation] = useState<SearchInformation>({selectedFilters: {}});
    const [savedSearchSelectionLoaded, setSavedSearchSelectionLoaded] = useState(false);
    const [searchSelectionData, setSearchSelectionData] = useState((new FilterSelection()).serialize());
    const searchSelection = FilterSelection.deserialize(searchSelectionData);
    const [activeSearch, setActiveSearch] = useState(new FilterSelection());
    const [aggregations, setAggregations] = useState<Aggregations>([]);
    const [getAggregations, {loading, data}] = useLazyQuery(GET_AGGREGATIONS_AND_USER);
    const [activeTab, setActiveTab] = useState(0);
    const [aggregationsInitialized, setAggregationsInitialized] = useState(false);
    const toggle = tab => {
        activeTab !== tab && setActiveTab(tab)
    }

    const [saveLastSelectedSearchFilter] = useMutation(SAVE_LAST_SELECTED_SEARCH_FILTER, {
        onCompleted: (): void => {
            const {urlPath, urlParams} = searchSelection.getPropertiesForLink();
            router.push({
                pathname: urlPath,
                query: urlParams
            });
        }
    });

    useEffect(() => {
        const sessionId = getSessionId();
        setSessionId(sessionId);
        apollo.query({
            query: GET_AGGREGATIONS_AND_USER,
            variables: {
                uuid: sessionId,
                filter: activeSearch.toGraphQLFilterInput(),
                names: ['COUNTRIES', 'COUNTIES', 'ATTRIBUTES', 'PERSONS']
            }
        })
        apollo.query({
            query: GET_AGGREGATIONS_AND_USER,
            variables: {
                uuid: sessionId,
                filter: activeSearch.toGraphQLFilterInput(),
                names: ['AREAS']
            }
        })
    }, [activeSearch])

    const createSearchLink = (children) => {
        const {urlPath, urlParams} = searchSelection.getPropertiesForLink();

        return (
            <Link
                href={{
                    pathname: urlPath,
                    query: urlParams,
                }}
                passHref={true}
            >
                {children}
            </Link>
        )
    }


    const updateSearchSelection = (newSearchSelection: FilterSelection, refreshAggregations = false) => {
        setSearchSelectionData(newSearchSelection.serialize());

        if (refreshAggregations) {
            updateAggregations(newSearchSelection);
        }
    }

    const clearSearchSelection = (name: string): void => {
        switch (name) {
            case 'destination':
                searchSelection.destinationSeoId = undefined;
                updateSearchSelection(searchSelection, true);
                break;
            case 'dates':
                searchSelection.startDate = undefined;
                searchSelection.endDate = undefined;
                updateSearchSelection(searchSelection, true);
                break;
            case 'party':
                searchSelection.adults = undefined;
                searchSelection.children = undefined;
                searchSelection.babies = undefined;
                searchSelection.dogs = undefined;
                updateSearchSelection(searchSelection, true);
                break;
            case 'filters':
                searchSelection.facilitySeoIds = undefined;
                updateSearchSelection(searchSelection, true);
                break;
        }
    }

    const getIconName = (name): string => {
        switch (name) {
            case 'house':
                return 'fas fa-house-user';
            case 'ship':
                return 'fas fa-ship';
            case 'van':
                return 'fas fa-shuttle-van';
            default:
                return 'fas fa-search';
        }
    }

    useEffect(() => {
        const sessionId = getSessionId();
        setSessionId(sessionId);

        initializeAggregations();
    }, [])

    useEffect(() => {
            if (data && data.aggregations) {
                setAggregations(data.aggregations.aggregations)
                setSearchInformation(data.aggregations.searchInformation);
            }

            if (savedSearchSelectionLoaded) {
                return;
            }

            if (data && data.user && data.user.lastSelectedSearchFilter) {
                const searchSelection = FilterSelection.deserialize(data.user.lastSelectedSearchFilter);
                updateSearchSelection(searchSelection, true);
                setSavedSearchSelectionLoaded(true);
            }
        }
        , [data])

    useEffect(() => {
            if (!aggregationsInitialized) {
                return;
            }

            getAggregations({
                variables: {
                    uuid: sessionId,
                    filter: activeSearch.toGraphQLFilterInput(),
                    names: ['COUNTRIES', 'COUNTIES', 'PERSONS']
                }
            });
        }
        , [activeSearch, aggregationsInitialized])

    const initializeAggregations = () => {
        setAggregationsInitialized(true);
    }

    const updateAggregations = (searchSelection) => {
        setActiveSearch(searchSelection);
    };

    const onSearchLinkClick = (): void => {
        saveLastSelectedSearchFilter({
            variables: {
                uuid: sessionId,
                searchFilter: searchSelection.toGraphQLFilterInput()
            },
        })
    }

    return (
        <>
            <Nav tabs className="search-bar-nav-tabs mt-5">
                {navigationData.searchTabs && navigationData.searchTabs.tabs.map((tab, index) =>
                    <NavItem
                        key={index}
                        className={index < navigationData.searchTabs.tabs.length - 1 ? 'mr-1' : ''}

                    >
                        <NavLink
                            onClick={() => toggle(index)}
                            className={activeTab === index ? 'active' : ''}
                        >
                            <span className="d-lg-none">
                                <i className={`large-tab-icon ${getIconName(tab.icon)}`}/>
                            </span>
                            <span className="d-none d-lg-block">{tab.title}</span>
                        </NavLink>
                    </NavItem>
                )}

            </Nav>
            <div className="search-bar search-bar-with-tabs p-3 p-lg-4">
                <TabContent activeTab={activeTab}>
                    {navigationData.searchTabs && navigationData.searchTabs.tabs.map((tab, index) =>
                        <TabPane
                            tabId={index}
                            key={index}
                        >
                            {index === 0 &&
                            <>
                                <LargeScreenComponent>
                                    <DesktopCottageSearch
                                        initializeAggregations={initializeAggregations}
                                        updateSearchSelection={updateSearchSelection}
                                        updateAggregations={updateAggregations}
                                        clearSearchSelection={clearSearchSelection}
                                        searchSelection={searchSelection}
                                        getAggregation={(aggregation) => getAggregation(aggregation, aggregations)}
                                        searchLink={createSearchLink}
                                        onSearchLinkClick={() => onSearchLinkClick()}
                                        searchInformation={searchInformation}
                                    />
                                </LargeScreenComponent>
                                <SmallScreenComponent>
                                    <MobileCottageSearch/>
                                </SmallScreenComponent>
                            </>
                            }

                            {index === 1 &&
                            <>
                                <LargeScreenComponent>
                                    <DesktopCamperSearch />
                                </LargeScreenComponent>
                                <SmallScreenComponent>
                                    <MobileCamperSearch />
                                </SmallScreenComponent>
                            </>
                            }
                        </TabPane>
                    )}
                </TabContent>
            </div>
        </>
    );
}

export default withApollo(HomePageSearchBar,
    {
        getDataFromTree
    }
);
