import React, {useState} from 'react';
import {Button, Col, Row} from "reactstrap";
import dynamic from "next/dynamic";

const MobileCottageSearchModal = dynamic(
    () => import('../../../CottageSearch/MobileCottageSearchModal'),
    {ssr: false}
);

const MobileCottageSearch = () => {
    const [modal, setModal] = useState(false);
    const toggleModal = (): void => setModal(!modal);

    return (
        <Row>
            <Col sm="12">
                <Button block color="light" onClick={toggleModal}>
                    <i className="fa fa-search text-primary mobile-search-icon"/>
                    <span className="mobile-search-button">Vakantiehuis zoeken</span>
                </Button>
            </Col>
            {modal &&
                // @ts-ignore
                <MobileCottageSearchModal isOpen={modal} toggle={() => toggleModal()}/>
            }
        </Row>
    )
}

export default MobileCottageSearch;
