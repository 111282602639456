import React, {useState} from 'react';
import {Button, ButtonDropdown, Col, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown} from "reactstrap";
import dynamic from "next/dynamic";
import 'react-dates/initialize';
import {FilterSelection} from "../../../../types/filterSelection";
import {SearchInformation} from "../../../../types/graphql/searchInformation";
import Icon from "../../../Icon";

const Destination = dynamic(
    () => import('./Destination'),
    {ssr: false}
);

const TravelParty = dynamic(
    () => import('./TravelParty'),
    {ssr: false}
);

const Dates = dynamic(
    () => import('./Dates'),
    {ssr: false}
);

const DateRangePicker = dynamic(
    () => import('react-dates').then(index => index.DateRangePicker),
    {ssr: false}
);

interface DesktopCottageSearchProps {
    updateSearchSelection: (searchSelection: FilterSelection, refresh: boolean) => void,
    clearSearchSelection: (name: string) => void,
    searchSelection: FilterSelection,
    getAggregation: (name: string) => any,
    searchLink: any,
    initializeAggregations: () => void,
    updateAggregations: (searchSelection: FilterSelection) => void,
    onSearchLinkClick: () => void,
    searchInformation: SearchInformation
}

const DesktopCottageSearch = ({
                                  updateSearchSelection,
                                  clearSearchSelection,
                                  searchSelection,
                                  getAggregation,
                                  searchLink,
                                  initializeAggregations,
                                  updateAggregations,
                                  onSearchLinkClick,
                                  searchInformation
                              }: DesktopCottageSearchProps) => {
    const [showDestinations, setShowDestinations] = useState(false);
    const [showTravelParty, setShowTravelParty] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [partyDropdownOpen, setPartyDropdownOpen] = useState(false);
    const [calendarDropdownOpen, setCalendarDropdownOpen] = useState(false);

    return (
        <Row>
            <Col lg="3">
                <Row className="mb-2">
                    <Col>
                        <span className="home-search-input-label">
                            Bestemming
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col
                        className="d-flex align-items-center form-group no-divider"
                    >
                        <ButtonDropdown isOpen={showDestinations} toggle={() => setShowDestinations(!showDestinations)} className="btn-group-main-search" direction="down">
                            <DropdownToggle
                                onClick={() => {
                                    initializeAggregations();
                                    setShowDestinations(true)
                                }}
                                color="light"
                                caret
                                className="btn-main-search">
                                {searchInformation.destinationSummary &&
                                <span className={`${searchInformation.destinationSummary ? 'selected-search-text search-button-text' : ''}`}>
                                    <Icon
                                        icon="checkmark-1"
                                        className="black"
                                    />
                                    {" "}
                                    {searchInformation.destinationSummary}
                                </span>
                                }

                                {!searchInformation.destinationSummary &&
                                <>Waar wilt u naartoe?</>
                                }
                            </DropdownToggle>
                            {searchInformation.selectedFilters.country &&
                            <Button
                                onClick={() => clearSearchSelection('destination')}
                                className="delete-button"
                                close
                            />
                            }
                            <DropdownMenu
                                positionFixed={true}
                                flip={false}
                            >
                                <Row>
                                    <Col>
                                        <Button
                                            onClick={() => setShowDestinations(false)}
                                            color="link"
                                            className="float-right text-gray-dark"
                                        >Sluiten X</Button>
                                    </Col>
                                </Row>
                                {showDestinations &&
                                <Destination
                                    updateSearchSelection={updateSearchSelection}
                                    searchSelection={searchSelection}
                                    getAggregation={getAggregation}
                                    searchInformation={searchInformation}
                                    triggerClose={() => setShowDestinations(false)}
                                />
                                }
                            </DropdownMenu>
                        </ButtonDropdown>
                    </Col>
                </Row>
            </Col>
            <Col
                md="12"
                lg="4"
            >
                <Row className="mb-2">
                    <Col>
                        <span className="home-search-input-label">Aankomst en vertrekdatum</span>
                    </Col>
                </Row>
                <Row>
                    <Col
                        className="d-flex align-items-center form-group no-divider"
                    >
                        <UncontrolledButtonDropdown
                            direction="down"
                            isOpen={calendarDropdownOpen}
                            toggle={() => {
                                if (calendarDropdownOpen) {
                                    updateAggregations(searchSelection);
                                }
                                setCalendarDropdownOpen(!calendarDropdownOpen)
                            }
                            }
                            className="btn-group-main-search">
                            <DropdownToggle
                                onClick={() => {
                                    setShowCalendar(true)
                                }}
                                color="light"
                                caret
                                className="btn-main-search">
                                <span className={`search-button-text ${searchInformation.datesSummary ? 'selected-search-text' : ''}`}>
                                    {searchInformation.datesSummary &&
                                        <>
                                            <Icon
                                                icon="checkmark-1"
                                                className="black"
                                            />
                                            {" "}
                                            {searchInformation.datesSummary}
                                        </>
                                    }

                                    {!searchInformation.datesSummary &&
                                    <>Selecteer datums</>
                                    }
                                </span>
                            </DropdownToggle>
                            {searchInformation.datesSummary &&
                            <Button
                                onClick={() => clearSearchSelection('dates')}
                                className="delete-button"
                                close
                            />
                            }
                            <DropdownMenu
                                positionFixed={true}
                                flip={false}
                            >
                                <>
                                    <Row>
                                        <Col>
                                            <Button
                                                onClick={() => setCalendarDropdownOpen(false)}
                                                color="link"
                                                className="float-right text-gray-dark"
                                            >Sluiten X</Button>
                                        </Col>
                                    </Row>
                                    {showCalendar &&
                                    <Dates
                                        searchSelection={searchSelection}
                                        updateSearchSelection={updateSearchSelection}
                                        searchInformation={searchInformation}
                                    />
                                    }
                                </>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </Col>
                </Row>
            </Col>
            <Col
                md="12"
                lg="5"
            >
                <Row className="mb-2">
                    <Col>
                        <span className="home-search-input-label">Reisgezelschap</span>
                    </Col>
                </Row>
                <Row>
                    <Col
                        lg="8"
                    >
                        <UncontrolledButtonDropdown
                            className="btn-group-main-search"
                            isOpen={partyDropdownOpen}
                            toggle={() => {
                                if (partyDropdownOpen) {
                                    updateAggregations(searchSelection);
                                }
                                setPartyDropdownOpen(!partyDropdownOpen)
                            }
                            }
                        >
                            <DropdownToggle
                                color="light"
                                caret
                                className="btn-main-search"
                                onClick={() => {
                                    setShowTravelParty(true)
                                }}
                            >
                                <span className={`search-button-text ${searchInformation.partySummary ? 'selected-search-text' : ''}`}>
                                    {searchInformation.partySummary &&
                                        <>
                                            <Icon
                                                icon="checkmark-1"
                                                className="black"
                                            />
                                            {" "}
                                            {searchInformation.partySummary}
                                        </>
                                    }

                                    {!searchInformation.partySummary &&
                                    <>Met wie reist u?</>
                                    }
                                </span>
                            </DropdownToggle>
                            {searchInformation.partySummary &&
                            <Button
                                onClick={() => clearSearchSelection('party')}
                                className="delete-button"
                                close
                            />
                            }
                            <DropdownMenu
                                className="home-search-dropdown"
                                positionFixed={true}
                                flip={false}
                            >
                                <>
                                    <Row>
                                        <Col>
                                            <Button
                                                onClick={() => setPartyDropdownOpen(false)}
                                                color="link"
                                                className="float-right text-gray-dark"
                                            >Sluiten X</Button>
                                        </Col>
                                    </Row>
                                    {showTravelParty &&
                                    <TravelParty
                                        searchSelection={searchSelection}
                                        updateSearchSelection={updateSearchSelection}
                                        searchInformation={searchInformation}
                                    />}
                                </>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </Col>
                    <Col
                        lg="4"
                        className="mt-4 mt-lg-0"
                    >

                        <Button
                            onClick={() => onSearchLinkClick()}
                            color="primary"
                            block
                            className={`h-100`}
                        >

                            <span className="d-lg-none">Vakantiehuis zoeken</span>
                            <span className="d-none d-lg-block">Zoeken</span>
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>

    )
}

export default DesktopCottageSearch;
